@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap');

:root {
    --cerna: #120b0b;
}

body {
    font-family: 'Poppins', sans-serif;
}

::selection {
    @apply bg-[var(--cerna)] text-white;
}

h1,
h2,
h3,
h4,
#menu {
    font-family: "GFS Didot", serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .4rem;
    text-transform: uppercase;
}

p {
    @apply leading-loose tracking-wide text-[var(--cerna)] text-base;
}

#menu .active {
    @apply font-bold;
}

.container {
    @apply m-auto p-8 md:p-0;
}

img {
    @apply w-full h-auto;
}

.btn-primary {
    @apply px-4 py-2 border border-t-2 border-l-2 border-[var(--cerna)] text-[var(--cerna)] bg-white hover:bg-[var(--cerna)] hover:text-white transition-all uppercase;
}

.btn-secondary {
    @apply px-4 py-2 border border-t-2 border-l-2 border-white text-white bg-black/25 hover:text-[var(--cerna)] hover:bg-white transition-all text-base;
}

.btn-secondary {
    font-family: "GFS Didot", serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .4rem;
    text-transform: uppercase;
}

th {
    @apply bg-[var(--cerna)] text-white font-normal border border-[var(--cerna)] p-4 text-start;
}

td {
    @apply border border-[var(--cerna)] p-4;
}

li::before {
    content: ">";
    margin-right: .5rem;
}

#lightbox img {
    width: auto;
}

#left {
    background-image: url("https://adela-kavalirova-kavalier-photography.onrender.com/assets/logo/AK_black.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
    #left {
        background-size: contain;
    }

    #btn-filters button {
        max-width: 48% !important;
    }
}

.slick-dots {
    bottom: -50px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100px;
    color: white;
}

.slick-dots li::before {
    content: "";
}

.slick-dots li.slick-active button:before {
    color: white !important;
    opacity: 1 !important;
    font-size: 10px !important;
}

.slick-dots li button:before {
    color: white !important;
    opacity: .7 !important;
    font-size: 7px !important;
}

#btn-filters button {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 24%;
    height: auto;
    aspect-ratio: 4/3;
    position: relative;
}

#btn-filters button p {
    color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: "GFS Didot", serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .4rem;
    text-transform: uppercase;
}

#btn-filters button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: all;
}

#btn-filters .active {
    opacity: 0.7;
}

#btn-filters button:hover::before {
    opacity: 0.5;
}

.btn-vse {
    background: url('/public/assets/gallery/rodina/rodina\ \(21\).webp');
}

.btn-rodina {
    background: url('/public/assets/gallery/rodina/rodina\ \(5\).webp');
}

.btn-tehotenske {
    background: url('/public/assets/gallery/tehotenske/tehotenske\ \(3\).webp');
}

.btn-newborn {
    background: url('/public/assets/gallery/newborn/newborn\ \(5\).webp');
}

.btn-pary {
    background: url('/public/assets/gallery/pary/pary\ \(1\).webp');
}

.embla {
    overflow: hidden;
}

.embla__container {
    display: flex;
}

.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
}

.slide-desktop {
    display: block;
}

.slide-mobile {
    display: none;
}

.embla img {
    @apply object-cover h-full;
}

@media only screen and (max-width: 768px) {
    .slide-desktop {
        display: none;
    }

    .slide-mobile {
        display: block;
    }

    .embla img {
        @apply h-screen;
    }
}

/* Embla Dots Styles */
.embla-dots {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.embla-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 5px;
    cursor: pointer;
}

.embla-dot.is-selected {
    background-color: rgba(255, 255, 255, 0.9);
}